function toggleDropdown(element) {

    const dropdown = document.querySelector(`[aria-labelledby='${element.getAttribute('id')}']`);
    if(dropdown) {
        dropdown.style.right = "0px";
        dropdown.classList.toggle('show');
        dropdown.classList.toggle('d-none');
    }

    window.addEventListener('click', (event) => {
        let clickedTarget = event.target;

        if (clickedTarget === dropdown || clickedTarget === element) {
            return;
        }

        if (dropdown.contains(clickedTarget) || element.contains(clickedTarget)) {
            return;
        }

        dropdown.classList.remove('show');
        dropdown.classList.add('d-none');
    });

}

Liferay.ThemeDisplay = Liferay.ThemeDisplay || {};
Liferay.ThemeDisplay.toggleDropdown = toggleDropdown;

